import React from 'react'
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from './Navbar';
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className='home-container'>
      <Navbar />
      <div className='home-banner-container'>
        <div className='home-bannerImage-container'>
            {/* <img src={BannerBackground} alt=''/> */}
        </div>
        <div className='droit-travail' >
          <div className='home-text-section' >
            <h1 className='primary-heading'>
              <br />
                Contact
            </h1>
            <p className='primary-text'>
                  <li className="listStyleType">Office: (+33) 01 43 72 44 80</li>
                  <li className="listStyleType">Fax: (+33) 01 43 72 44 80</li>
                  <li className="listStyleType">Mobile: (+33) 01 33 80 70 21 72</li>
                  {/* <li class="font-weight-medium mb-5">Email: <a v-bind:href="'mailto:' + email">{{email}}</a></li> */}
                  <li className="listStyleType">Métro: ligne 9 Station: Rue des boulets</li>
                  <li className="listStyleType">Bus: 56 Station: Rue des boulets</li>

            </p>
            <br />
            <br />
{/*             <button className='secondary-button' >
                Contactez-nous <FiArrowRight />
            </button> */}
          </div>
            <div className='home-image-section'>
            {/* <img src={BannerImage} alt=''/> */}
          </div>
        </div>
{/*         <div className='droit-travail' >
          <div className='home-text-section' >
            <h1 className='primary-heading'>
                Droit Du Travail
            </h1>
            <p className='primary-text'>
                Le Droit du travail est....
            </p>
            <button className='secondary-button' >
                Contactez-nous <FiArrowRight />
            </button>
          </div>
            <div className='home-image-section'>
            {/ * <img src={BannerImage} alt=''/> * /}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home
