import React, { useState } from 'react';
import Logo from "../Assets/Logo.svg";
import Lawyer from "../Assets/lawyer_icon.png";
import { BsCart2 } from 'react-icons/bs';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { 
    Box, 
    colors, 
    Drawer, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
} from '@mui/material';
import List from '@mui/material/List';
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';


const Navbar = () => {
    const [ openMenu, setOpenMenu ] = useState(false);
    const menuOptions =[ 
        { text: "Home",
            icon: <HomeIcon />
        },
        { text: "Droit De La Famille",
            icon: <HomeIcon />
        },
/*         { text: "Droit Immobilier",
            icon: <HomeIcon />
        },
        { text: "Droit Des Affaires",
            icon: <HomeIcon />
        },
        { text: "Droit International",
            icon: <HomeIcon />
        },
        { text: "Droit Du Travail Et Droit Social",
            icon: <HomeIcon />
        },
        { text: "Droit Des Etrangers",
            icon: <HomeIcon />
        },
        { text: "Droit Penal",
            icon: <HomeIcon />
        },
        { text: "Home",
            icon: <HomeIcon />
        },
        { text: "Home",
            icon: <HomeIcon />
        }, */
        // { text: "About",
        //     icon: <InfoIcon />
        // }
        ,
        // { text: "Testimonials",
        //     icon: <CommentRoundedIcon />
        // },
        { text: "Contact",
            icon: <PhoneRoundedIcon />
        }
        // ,
        // { text: "Cart",
        //     icon: <ShoppingCartRoundedIcon />
        // },

    ];

  return (
    <nav>
        <div className="nav-logo-container">
            {/* <img src={Logo} alt="" /> */}
            <div>
                <img src={Lawyer} alt="" className='imgLawyer'/>  
                <h1 className='primary-heading divLawyer'>
                <br />
                Cabinet Sylvie FOADING-NCHOH <br /> <br />

                Maître Sylvie Foading-Nchoh exerce au barreau de Paris depuis 2003.  <br />
                    
                <p class="">Diplômée de l’Université de Lyon 3 (Doctorat en droit privé et Diplôme d’études Approfondies de droit) C.A.P.A. obtenu a l' E.F.B. (Ecole de formation du barreau de Paris),</p>
                <p class="">Exerce son activité au sein d'un cabinet groupé d'avocats.</p>
                <p class="">Le Cabinet est habilité à assurer des audiences sur tout le territoire de France.</p>
                </h1>
                <br />
            </div>
        </div>
{/*         <div className='navbar-links-container' >
            <a href=''>Home</a>
            <a href=''>About</a>
            <a href=''>Testimonials</a>
            <a href=''>Contact</a>
            <a href=''>
                <BsCart2 className='navbar-cart-icon' />
            </a>
            <button className='primary-button'>Bookings Now</button>
        </div> */}
        <div className='navbar-menu-container'>
            <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor='right'>
            <Box sx={{width:250}}
                role="presentation"
                onClick={() => setOpenMenu(false)}
                onKeyDown={()=> setOpenMenu(false)}
            >
                <List>
                    {menuOptions.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    )
                    )}

                </List>
            </Box>
        </Drawer>
    </nav>
  )
}

export default Navbar
